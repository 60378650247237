
import { BusinessUnitModel } from './BusinessUnitModel';
import { DepartmentModel } from './DepartmentModel';
import { OrganisationModel } from '@app/models/OrganisationModel';
import { ContractorSummaryModel } from './ContractorSummaryModel';
import { StringFilterUI } from '@syncfusion/ej2-angular-grids';

export class UserModel {    
    id: string;
    organisation: OrganisationModel;
    firstname: string;
    lastname: string;
    email: string;
    approvalLevel: number;
    department?: DepartmentModel;
    businessUnit: BusinessUnitModel;
    contractor : ContractorSummaryModel;
    language: string;
    timezone: string;
    active: boolean;
    locked: boolean;
    country: string;
    givenname: string;
    surname: string;
    viewOnly: boolean;
    isDefaultMember: boolean;
    hideFinancialData: boolean;
    selected: boolean;
    isManager: boolean;
    displayName: string;
    isAdmin: boolean;
    isCustomerAdmin: boolean;
    ssoEnabled: boolean;
    phoneNumber: string;
    isPrimaryContact: boolean;
    displayPhoto:any;
    eulaAccepted:boolean;
    eulaAcceptedDate?:string;
    address:string;
    latitude:number;
    longitude:number;
    addressLine1:string;
    addressLine2:string;
    stateBusinessLocation:any[];
    stateBusinessLocationName:string;
    cityOrSuburb:string;
    postcodeOfMainBusinessLocation:string;
    state:string;
    isAddressValidated:boolean;
}
