
import { Directive, Output, Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { AccountsService } from '@app/services/accounts.service';
import { UserModel } from '@app/models/UserModel';
import { CommonCodeComponent } from '@app/components/commoncode/commoncode.component';
import { SimpleModalService, SimpleModalComponent } from 'ngx-simple-modal';
import { catchError } from 'rxjs/operators';
import { OrganisationService } from '@app/services/organisation.service';
import { OrganisationModel } from '@app/models/OrganisationModel';
import { SelectedEventArgs, UploadingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { environment } from '@env/environment';
import { firstValueFrom } from 'rxjs';
import { OrganisationTenancyType } from '@app/constants';
import * as moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Directive()

export abstract class UserDetailsComponentBase extends SimpleModalComponent<any, boolean> {
    @Output()  changeUser: EventEmitter<any> = new EventEmitter();
    @Input()  user = {} as UserModel;  
    @Input()  modal: boolean = false;
    @Input()  isEdit: boolean = false;
    @Input()  isRecipient: boolean = false;
    @Input()  requestingUser = UserModel;
    imageUserPic: SafeUrl;
    imageUserPicSelectOnUpload: SafeUrl;
    imageOrgLogoPic: any;
    uploadedFiles: any[] = [];
    disableUpload: boolean = false;
    eulaAcceptedDate: string;
    eulaLink:string = '';

    path: Object = {};
    blob: any = '';
    public dropElement: HTMLElement;
   
    public userPrimaryContactDisable:boolean = false;
    public fields: Object = {text: 'displayName', value: 'id'};

    constructor(protected simpleModalService: SimpleModalService,  
                protected organisationService : OrganisationService,
                protected accountsService: AccountsService, 
                protected sanitizer: DomSanitizer, 
                protected commonComponent: CommonCodeComponent
    ) {
        super();
     }

    initBaseComponent() {
        this.path = { 
            saveUrl: `${environment.apiUrl}/api/v1/Images/uploadprofilephoto/${this.user.id}`,
            removeUrl: `${environment.apiUrl}/api/v1/Images/removeprofilephoto/${this.user.id}`,
         };
        this.dropElement = document.getElementsByClassName('ejs-uploader-div')[0] as HTMLElement; 

        this.setProfilePic();
        
        this.accountsService.cacheOrganisationLogoPicUrl$.subscribe(pic => {
            this.imageOrgLogoPic = this.sanitizer.bypassSecurityTrustUrl(pic);
        });
       
        this.eulaLink = environment.eulaLink
        // Convert date to YYYY-MM-DD format
        this.eulaAcceptedDate = moment(this.user.eulaAcceptedDate).format('YYYY-MM-DD');
    }
  
    async setProfilePic(){
        await firstValueFrom(this.accountsService.getUserProfilePictureURL(this.user.id)).then((res:any) => {
            var objectUrl = URL.createObjectURL(res);
            this.imageUserPic = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
            this.imageUserPicSelectOnUpload = this.imageUserPic;
        },
            catchError((err: any) => observableThrowError(this.errorHandler(err)))
        );
    }

    errorHandler(error: any): void {
    }

    public onChunkuploading(args: UploadingEventArgs): void {
        if (args.currentRequest) {
            args.currentRequest.setRequestHeader('Authorization', `Bearer ${localStorage.getItem("id_token")}`);
            args.currentRequest.setRequestHeader('ocp-apim-subscription-key', `${environment.apiKey}`);
            args.currentRequest.setRequestHeader('chunkId', `${this.blob}`);
        }
    }

    public onSuccess(args: any): void {      
        if(args.name == "success")
        {
            this.dropElement = document.getElementsByClassName('ejs-uploader-div')[0] as HTMLElement;     
        }
    }

    onSelected(args :SelectedEventArgs)
    {
        if(args.filesData.length > 0 && args.filesData[0].rawFile instanceof Blob)
        {
            var objectUrl = URL.createObjectURL(args.filesData[0].rawFile as Blob);
            this.imageUserPicSelectOnUpload = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
        }
    }

    onCloseClicked() {
        this.close();
      }

    showEULA() {
        const activeOrg = sessionStorage.getItem("organisationTenancyType");
        if(activeOrg == OrganisationTenancyType.RecipientFreemium) {
            return true;
        }
        return false;
    }
}

function observableThrowError(arg0: any): any {
    throw new Error('Function not implemented.');
}

